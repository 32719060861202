Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name";
exports.lastName = "Last Name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "Can't be blank";

exports.labelLegalText =
  "Legal Text: By signing up you agree to our Terms &amp; Conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalPrivacyPolicy = "Privacy Policy";
exports.btnTextSignUp = "Sign Up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.JoinTxt = "Join portal as";
exports.belowTxt = "Signing up with us is an effortless process. Once you submit the form,";
exports.belowTxt2 = "we'll email you with the warm welcome and all the information you need to know.";
exports.loginTxt = " Log In";
exports.singUpTxt = "Sign Up";
exports.companyTxt = "Company Name";
exports.emailTxt = "Email";
exports.asAgentTxt = "As an Agent";
exports.asStudentTxt = "As a Student";
exports.noEmp = "No. of Employees";
exports.selectEmp = "Select Employees";
exports.selectCountry = "Country";
exports.selectState = "State";
exports.selectCity = "City";
exports.selectCountryPlaceholderTxt = "Select Country";
exports.selectStatePlaceholderTxt = "Select State";
exports.selectCityPlaceholderTxt = "Select City";
exports.agreeTxt = "Please agree ";
exports.agreePrivacyTxt = "Privacy Policy ";
exports.agreeTermsTxt = "Terms and Conditions";
exports.alreadyTxt = "Already have an account?";
exports.signupTxt = "Sign Up";
exports.starTxt = "*";
exports.companyPlaceholderTxt = "Your Company Name";
exports.emailPlaceholderTxt = "Your Email";
exports.cityPlaceholderTxt = "City Name";
exports.thanksTxt = "Thank you for joining, we have received your information. We'll review it and get back to you soon.";
exports.signupContentType = "application/json";
exports.signupApiMethod = "POST";
exports.signupEndPoint = "/account_block/accounts";
exports.resetAPIContentType = "application/json";
exports.resetAPIApiMethod = "POST";
exports.resetAPIEndPoint = "bx_block_forgot_password/otps";
exports.andTxt = "and";
// Customizable Area End
