import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Color } from "@material-ui/lab/Alert";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ErrorResponse {
  [key: string]: string;
}

interface ValidResponseType {
  errors: ErrorResponse[];
  account: ValidResponseType;
  message: object;
  data: object;
}
interface InvalidResponseType {
  errors: Array<string>;
}
interface LoginDataType {
  "account": {
    "data": {
      "id": "118",
      "type": "email_account",
      "attributes": {
        "first_name": "fsf",
        "company_name": "test",
        "email": "dsddsdsdsdsdsaf@yopmail.com",
        "no_of_employees": 20,
        "country": "AU",
        "state": "MP",
        "city": "Indore",
        "verification_type": "email_account",
        "approval_status": "approved",
        "is_logged_in": true,
        "created_at": "2024-03-18T07:23:07.624Z",
        "updated_at": "2024-03-18T09:17:11.226Z",
        "role" :{
          "id": number,
          "name": string,
          "created_at": string,
          "updated_at": string,
          "read_access": boolean,
          "written_access": boolean,
          "view_access": boolean
        }
      }
    },
    "meta": {
      "token": [
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTE4LCJleHAiOjE3MTA4NDAzMjgsInRva2VuX3R5cGUiOiJsb2dpbiJ9.AkaL8Mgtqhmkq06AiSTd-KL5YYkCsOcCYX0QX07Wj3uL1iXfdlFMecgAyjPod4PGB_9TSA8VUrwo0m7bgsI5Xg"
      ]
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  pswrdValue: string;
  pswrdError: boolean;
  pswrdErrorTxt: string;
  emailValue: string;
  emailError: boolean;
  emailErrorTxt: string;
  visiblePswrd: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  toggleDrawer: boolean;
  UserRole: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  loginAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      pswrdValue: "",
      pswrdError: false,
      pswrdErrorTxt: "",
      emailValue: "",
      emailError: false,
      emailErrorTxt: "",
      visiblePswrd: true,
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      toggleDrawer: false,
      UserRole: '' 
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const UserRole = await getStorageData("role")
    this.setState({
      UserRole: UserRole
    })
    // Customizable Area End
  }

  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  visiblePswrd = () => {
    this.setState({ visiblePswrd: !this.state.visiblePswrd });
  };

  handleLogin = () => {
    let isCheck = true
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
    if (this.state.emailValue.length === 0) {
      isCheck = false
      this.setState({ emailError: true, emailErrorTxt: "Email is required." })
    }
    else if (!emailRegex.test(this.state.emailValue.trim())) {
      isCheck = false
      this.setState({ emailError: true, emailErrorTxt: "Please enter a valid Email Address." })
    }
    if (this.state.pswrdValue.length === 0) {
      isCheck = false
      this.setState({ pswrdError: true, pswrdErrorTxt: "Password is required." })
    }
    else if (!passwordRegex.test(this.state.pswrdValue)) {
      isCheck = false
      this.setState({ pswrdError: true, pswrdErrorTxt: "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character." })
    }
    if (isCheck) {
      this.postLoginAPI()
    }
  }

  handlePswrdChange = (event: { target: { value: string } }) => {
    const pswrdValue = event.target.value;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
    this.setState({ pswrdValue: pswrdValue }, () => {
      if (pswrdValue === "") {
        this.setState({
          pswrdError: true,
          pswrdErrorTxt: "Password is Required.",
        });
      } if (!passwordRegex.test(pswrdValue)) {
        this.setState({
          pswrdError: true,
          pswrdErrorTxt:
            "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
        });
      } else {
        this.setState({ pswrdError: false, pswrdErrorTxt: "" });
      }
    });
  };

  handleEmailChange = (event: { target: { value: string; }; }) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
    const emailValue = event.target.value;
    if (emailValue === "") {
      this.setState({
        emailErrorTxt: "Email is required.",
        emailError: true,
        emailValue: emailValue
      });
    } else if (!emailRegex.test(emailValue.trim())) {
      this.setState({
        emailErrorTxt: "Please enter a valid Email Address.",
        emailError: true,
        emailValue: emailValue
      });
    }
    else {
      this.setState({
        emailErrorTxt: "",
        emailError: false,
        emailValue: emailValue
      });
    }
  };

  navigateToForgotPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailVerification");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );
    body
      &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  postLoginAPI = async () => {
    const body = {
      data: {
        attributes: {
          email: this.state.emailValue.trim(),
          password: this.state.pswrdValue,
          verification_type: "email_account"
        }
      }
    }
    this.loginAPICallId = await this.apiCall({
      contentType: configJSON.loginContentType,
      method: configJSON.loginApimethod,
      endPoint: configJSON.loginEndPoint,
      body: body
    });
  };

  navigateToRegistration = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.account);
  };

  inValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: LoginDataType) => {
    if (apiRequestCallId === this.loginAPICallId) {
      this.loginSucessCallBack(responseJson);
    }
  };

  apiFailureCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.loginAPICallId) {
      this.loginFailureCallBack(responseJson);
    }
  }

  loginSucessCallBack = (responseJson: LoginDataType) => {
    setStorageData("token", responseJson.account.meta.token[0])
    setStorageData("userDetails", responseJson.account.data.id)
    setStorageData("token", responseJson.account.meta.token[0])
    setStorageData("role",responseJson.account.data.attributes.role.name)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DashBoard"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  loginFailureCallBack = (responseJson: ValidResponseType) => {
    const errorKey: string = Object.keys(responseJson.errors[0])[0];
    this.setState({
      isAlert: true,
      alertMsg: responseJson.errors[0][errorKey],
      alertType: "error"
    })
  }
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
      if (responseJson && responseJson.errors) {
        this.apiFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
