import React from "react";
// Customizable Area Start
import ForgotPasswordEmailController, { Props } from "./ForgotPasswordEmailController.web";
import * as IMG_CONST from "./assets";
import { Box, Button, Snackbar, TextField, Typography, styled } from "@material-ui/core";
import { configJSON } from "./ForgotPasswordController";
import AppHeader from "../../../components/src/AppHeader.web";
import Alert from "@material-ui/lab/Alert";
import { Bounce, ToastContainer } from "react-toastify";
// Customizable Area End

export default class ForgotPasswordEmail extends ForgotPasswordEmailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderEmail = () => {
        return (
            <MainBoxEmail>
                <FirstBox>
                    <ForgotPswrdTxt
                        data-test-id="forgotPswrdTxtTestId"
                    >
                        {configJSON.emailVerificationTxt}
                    </ForgotPswrdTxt>
                    <ForgotPsrwdInnerBox>
                        <InputMainFrgtPswrdBox>
                            <InputTxt>
                                {configJSON.emailTxt}
                                <StarTxt> {configJSON.starTxt}</StarTxt>
                            </InputTxt>
                            <InputField
                                data-test-id="emailChangeTestID"
                                onChange={this.handleEmailChange}
                                placeholder={configJSON.emailTxtPlaceHolder}
                                value={this.state.emailValue}
                            />
                            {this.state.emailError && (
                                <RequiredTxt>{this.state.emailErrorTxt}</RequiredTxt>
                            )}
                        </InputMainFrgtPswrdBox>
                        <SaveButton
                            onClick={this.onEmailClick}
                            data-test-id="emailClickBtn"
                        >
                            {configJSON.forgotBtnTxt}
                        </SaveButton>
                    </ForgotPsrwdInnerBox>
                    <BackBtnBox >
                        <BackButton
                            data-test-id="LoginPageTestId"
                            onClick={this.navigateToLoginPage}
                        >
                            {configJSON.backToLoginTxt}
                        </BackButton>
                    </BackBtnBox>
                </FirstBox>
                <SecondBox>
                    <SideImg src={IMG_CONST.sideImgEmailVerification} />
                </SecondBox>
            </MainBoxEmail>
        )
    }

    showErrorToast = () => {
        return (
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            theme="colored"
            transition={Bounce}
          />
        )
      }

    renderPasswordBox = () => {
        return (
            <RenderPassword>
                <PswdMainImg src={IMG_CONST.sideImgSetPswrd} />
                <PswrdJoinTxt>{configJSON.forgetSuccessMessage}</PswrdJoinTxt>
                <PswrdJoinTxtEmail>{this.state.emailValue}</PswrdJoinTxtEmail>
                <PswrdBelowTxt>{configJSON.forgetMailCheckText}</PswrdBelowTxt>
                <LoginBtn onClick={this.resetPswrdAPI}>Resend Link</LoginBtn>
            </RenderPassword>)
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader
                    navigation={this.props.navigation}
                    userName={undefined}
                    isUserLogin={false}
                    isLogin={false}
                    onHomeClick={this.onHomeClick}
                    onLoginClick={undefined}
                    onSignUpClick={undefined}
                    data-test-id="appHeaderTestId"
                    handleToggle={this.handleToggle}
                    toggleData={this.state.toggleDrawer}
                    role={undefined}
                />
                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    onClose={this.oncloseAlert}
                    data-test-id="alertTestId"
                >
                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
                {this.showErrorToast()}
                {
                    this.state.isSuccess
                        ?
                        this.renderPasswordBox()
                        :
                        this.renderEmail()
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const RenderPassword = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px 0px"
})

const PswrdBelowTxt = styled(Typography)({
    fontSize: "16px",
    color: "#475569",
    width: "400px",
    textAlign: "center",
    margin: "0px 20px",
    fontFamily: "Plus Jakarta Sans",
    "@media (max-width: 768px)": {
        fontSize: "16px",
        width: "300px"
    },
    "@media (max-width: 600px)": {
        fontSize: "14px",
        width: "200px"
    }
});
const EmailButton = styled(Button)({
    width: "400px",
    backgroundColor: "#BB393D",
    color: "#fff",
    fontSize: "14px",
    textTransform: "capitalize",
    fontFamily: "Plus Jakarta Sans",
    height: "50px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#BB393D"
    }
});
const PswrdJoinTxt = styled(Typography)({
    color: "#000",
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: "Plus Jakarta Sans",
    "@media (max-width: 768px)": {
        fontSize: "28px"
    },
    "@media (max-width: 600px)": {
        fontSize: "24px"
    }
})

const PswrdJoinTxtEmail = styled(Typography)({
    color: "#000",
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: "Plus Jakarta Sans",
    marginBottom:"20px",
    "@media (max-width: 768px)": {
        fontSize: "28px"
    },
    "@media (max-width: 600px)": {
        fontSize: "24px"
    }
})

const LoginBtn = styled(Button)({
    background: '#fff',
    border: "2px solid #BB393D",
    height: "40px",
    color: "#BB393D",
    padding: "0px 15px",
    textTransform: "unset",
    fontWeight: 600,
    borderRadius: "8px",
    margin: "30px",
});

const PswdMainImg = styled("img")({
    height: "350px",
    width: "350px",
    "@media (max-width: 768px)": {
        height: "300px",
        width: "300px"
    },
    "@media (max-width: 600px)": {
        height: "200px",
        width: "200px"
    }
});

const StarTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans"
});

const InputTxt = styled(Typography)({
    fontWeight: 600,
    fontSize: "14px",
    color: "#334155",
    fontFamily: "Plus Jakarta Sans"
});

const InputField = styled(TextField)({
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    padding: "0px 10px",
    justifyContent: "center",
    boxSizing: "border-box",
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none"
        },
        "&::before": {
            content: "none"
        },
        "&::after": {
            content: "none"
        }
    }
});

const RequiredTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px"
});

const InputMainFrgtPswrdBox = styled(Box)({
    margin: "0px 0px 20px 0px"
});

const BackBtnBox = styled(Box)({
    display: "flex",
    justifyContent: "center"
});

const SaveButton = styled(Button)({
    width: "100%",
    backgroundColor: "#BB393D",
    color: "#fff",
    fontSize: "14px",
    textTransform: "capitalize",
    fontFamily: "Plus Jakarta Sans",
    height: "50px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#BB393D"
    }
});

const MainBoxEmail = styled(Box)(({ theme }) => ({
    margin: "40px 0px",
    display: "flex",
    justifyContent: "space-around",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
        margin: "20px 20px"
    },
    [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
        alignItems: "center",
        margin: "10px"
    }
}));

const FirstBox = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "400px",
    justifyContent: "center",
    alignSelf: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
        padding: "20px"
    },
}));

const SecondBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
});

const ForgotPswrdTxt = styled(Typography)({
    color: "#B6373C",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Plus Jakarta Sans",
    textAlign: "center",
    margin: "0px 0px 5px 0px",
    "@media (max-width: 600px)": { fontSize: "16px" }
});

const ForgotPsrwdInnerBox = styled(Box)({
    padding: "30px 0px",
    "@media (max-width: 600px)": {}
});

const SideImg = styled("img")({
    height: "450px",
    width: "450px",
    "@media (max-width: 768px)": {
        height: "300px",
        width: "300px"
    },
    "@media (max-width: 600px)": {
        height: "200px",
        width: "200px"
    }
});

const BackButton = styled(Button)({
    width: "200px",
    color: "#0F172A",
    fontSize: "12px",
    textTransform: "unset",
    fontFamily: "Plus Jakarta Sans",
    height: "30px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#fff"
    }
});
// Customizable Area End