import React from "react";
// Customizable Area Start
import NewPasswordController, { Props } from "./NewPasswordController.web";
import { Box, Button, Snackbar, TextField, Typography, styled } from "@material-ui/core";
import { configJSON } from "./ForgotPasswordController";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert";
import AppHeader from "../../../components/src/AppHeader.web";
import { Formik } from "formik";
// Customizable Area End

export default class NewPassword extends NewPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSetPswrd = () => {
        return (
            <Formik
                initialValues={{
                    Password: this.state.pswrdValue || '',
                    ConfirmPassword: this.state.confrimPswrdValue || ''
                }}
                validationSchema={this.PasswordSetSchema}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={() => {
                    this.postNewpswrd()
                }}
            >
                {({ handleSubmit, setFieldValue, handleBlur, errors, values, touched }) => {

                    return (
                        <form data-test-id="setNewPassowrd" onSubmit={handleSubmit} autoComplete="off">
                            <MainBox>
                                <FirstBox>
                                    <SetPswrdTxt >
                                        {configJSON.setPswrdTxt}
                                    </SetPswrdTxt>
                                    <SetPsrwdInnerBox>
                                        <InputMainBox>
                                            <InputTxt>
                                                {configJSON.pswrdTxt}
                                                <StarTxt> {configJSON.starTxt}</StarTxt>
                                            </InputTxt>
                                            <InputField
                                                data-test-id="setPswrdChangeTestId"
                                                placeholder={configJSON.pswrdTxtPlaceHolder}
                                                onChange={(e) => {
                                                    this.handlePswrdChange(e, setFieldValue)
                                                }}
                                                value={values.Password}
                                                name="Password"
                                                onBlur={handleBlur}
                                            />
                                            {errors.Password && touched.Password && (<RequiredTxt>{errors.Password}</RequiredTxt>)}
                                        </InputMainBox>
                                        <InputMainBox>
                                            <InputTxt>
                                                {configJSON.confrimPswrdTxt}
                                                <StarTxt> {configJSON.starTxt}</StarTxt>
                                            </InputTxt>
                                            <InputField
                                                placeholder={configJSON.confrimPswrdTxt}
                                                onChange={(e) => {
                                                    this.handleConfirmPswrdChange(e, setFieldValue)
                                                }}
                                                value={values.ConfirmPassword}
                                                data-test-id="setConfirmPswrdChangeTestId"
                                                name="ConfirmPassword"
                                                onBlur={handleBlur}
                                            />
                                            {errors.ConfirmPassword && touched.ConfirmPassword && (<RequiredTxt>{errors.ConfirmPassword}</RequiredTxt>)}
                                        </InputMainBox>
                                        <SaveButton
                                            data-test-id="signUpBtnTestId"
                                            type="submit"
                                        >
                                            {configJSON.saveBtnTxt}
                                        </SaveButton>
                                    </SetPsrwdInnerBox>
                                    {this.state.roleName !== "counsellor" &&
                                        <BackBtnBox>
                                            <BackButton
                                                data-test-id="loginTestId"
                                                onClick={this.navigateToLoginPage}>
                                                {configJSON.backToLoginTxt}
                                            </BackButton>
                                        </BackBtnBox>
                                    }
                                </FirstBox>
                                <SecondBox>
                                    <SideImg src={IMG_CONST.sideImgSetPswrd} />
                                </SecondBox>
                            </MainBox>
                        </form>
                    )
            }}
            </Formik>
        );
    };

    renderSuccess = () => {
        return (
            <MainBox>
                <SuccessFirstBox>
                    <SuccessImg src={IMG_CONST.successImgSetPswrd} />
                    <SuccessTxt>
                        {configJSON.successfullyTxt} {configJSON.createPswrd}
                    </SuccessTxt>
                    <CreatePswrdTxt>
                        {""}
                    </CreatePswrdTxt>
                    <ContinueBtn onClick={this.navigateToLoginPage}>{configJSON.continueBtnTxt}</ContinueBtn>
                </SuccessFirstBox>
                <SecondBox>
                    <SideImg src={IMG_CONST.sideImgSuccessSetPswrd} />
                </SecondBox>
            </MainBox>
        );
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    onClose={this.oncloseAlert}
                    data-test-id="alertTestId"
                >
                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
                <AppHeader
                    isUserLogin={false}
                    isLogin={false}
                    onHomeClick={this.onHomeClick}
                    onLoginClick={undefined}
                    onSignUpClick={undefined}
                    data-test-id="appHeaderTestId"
                    handleToggle={this.handleToggle}
                    toggleData={this.state.toggleDrawer}
                    userName={undefined}
                    role={undefined}
                />
                {this.state.isSuccess ? this.renderSuccess() : this.renderSetPswrd()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const FirstBox = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "400px",
    alignSelf: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
        padding: "20px"
    }
}));

const InputField = styled(TextField)({
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none"
        },
        "&::before": {
            content: "none"
        },
        "&::after": {
            content: "none"
        }
    }
});

const MainBox = styled(Box)(({ theme }) => ({
    gap: "20px",
    margin: "40px 0px",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
        margin: "0px 20px"
    },
    [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
        alignItems: "center",
    }
}));

const InputTxt = styled(Typography)({
    color: "#334155",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Plus Jakarta Sans"
});

const InputMainBox = styled(Box)({
    margin: "0px 0px 20px 0px"
});

const StarTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans"
});

const RequiredTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px"
});

const SaveButton = styled(Button)({
    width: "100%",
    backgroundColor: "#BB393D",
    color: "#fff",
    fontSize: "14px",
    textTransform: "capitalize",
    fontFamily: "Plus Jakarta Sans",
    height: "50px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#BB393D"
    }
});

const BackBtnBox = styled(Box)({
    display: "flex",
    justifyContent: "center"
});

const SecondBox = styled(Box)({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 600px)": {
        display: "none"
    }
});

const ContinueBtn = styled(Button)({
    backgroundColor: "#BB393D",
    color: "#fff",
    width: "80%",
    fontSize: "14px",
    textTransform: "capitalize",
    fontFamily: "Plus Jakarta Sans",
    height: "50px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#BB393D"
    }
});

const SuccessFirstBox = styled(Box)({
    flexDirection: "column",
    margin: "20px 0px",
    width: "430px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
        width:" 100%"
    }
});

const SuccessImg = styled("img")({
    width: "200px",
    height: "200px",
    "@media (max-width: 600px)": {}
});

const SetPswrdTxt = styled(Typography)({
    color: "#B6373C",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Plus Jakarta Sans",
    textAlign: "center",
    margin: "0px 0px 5px 0px",
    "@media (max-width: 600px)": { fontSize: "16px" }
});

const SetPsrwdInnerBox = styled(Box)({
    padding: "30px 0px",
    "@media (max-width: 600px)": {}
});

const SuccessTxt = styled(Typography)({
    color: "#000",
    fontWeight: 500,
    fontSize: "20px",
    fontFamily: "Plus Jakarta Sans",
    lineHeight: 1,
    padding: "20px 0px",
    "@media (max-width: 600px)": { fontSize: "28px" }
});


const BackButton = styled(Button)({
    width: "200px",
    color: "#0F172A",
    fontSize: "12px",
    textTransform: "capitalize",
    fontFamily: "Plus Jakarta Sans",
    height: "30px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#fff"
    }
});

const CreatePswrdTxt = styled(Typography)({
    color: "#858585",
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Plus Jakarta Sans",
    margin: "0px 0px 15px 0px",
    "@media (max-width: 600px)": { fontSize: "14px" }
});

const SideImg = styled("img")({
    height: "450px",
    width: "450px",
    "@media (max-width: 768px)": {
        height: "300px",
        width: "300px"
    },
    "@media (max-width: 600px)": {
        height: "200px",
        width: "200px",
        display:"none"
    }
});
// Customizable Area End