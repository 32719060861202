import { Box, Button, Grid, Typography, styled } from '@material-ui/core';
import React, { Component } from 'react'
import * as IMG_CONST from "./assets";
import { getStorageData, setStorageData } from '../../../packages/framework/src/Utilities';
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";

type MyProps = {
    onAnyPageNavigate: (pageName: string) => void;
    renderButtonClick?: (value: string) => void;
}

interface FooterState {
    FooterLinks:{
        facebook: string,
        google: string,
        linkedin: string,
        twitter: string,
        instagram: string
    }
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface SocialLinkApiResonse {
    data: {
        id: number,
        name: string,
        social_link: string,
        created_at: string,
        updated_at: string,
        logo_url : {
            url : string
        }
    }
}

export default class Footer extends Component<MyProps, FooterState> {
    subScribedMessages: string[];
    constructor(props: MyProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.PostDetailDataMessage),
        ];

        this.state ={
            FooterLinks:{
                facebook: "",
                google: "",
                linkedin: "",
                twitter: "",
                instagram: ""
            }
        }

        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }

    FacebookApiCallId: string = "";
    GoogleApiCallId: string = "";
    LinkedinApiCallId: string = "";
    TwitterApiCallId: string = "";
    InstagramApiCallId: string = "";

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.GetResponseGoogleApiCall(apiRequestCallId,responseJson)
        this.GetResponseTwitterApiCall(apiRequestCallId,responseJson)
        this.GetResponseInstagramApiCall(apiRequestCallId,responseJson)
        this.GetResponseLinkedinApiCall(apiRequestCallId,responseJson)
        this.GetResponseFacebookApiCall(apiRequestCallId,responseJson)
    }

    // api call response set 

    GetResponseGoogleApiCall = (apiRequestCallId:string, responseJson:SocialLinkApiResonse ) =>{
        if (apiRequestCallId === this.GoogleApiCallId) {
            this.setState({
                FooterLinks:{
                    ...this.state.FooterLinks, google: responseJson.data.logo_url.url
                }
            })
        }
    }

    GetResponseTwitterApiCall = (apiRequestCallId:string, responseJson:SocialLinkApiResonse ) =>{
        if (apiRequestCallId === this.TwitterApiCallId) {
            this.setState({
                FooterLinks:{
                    ...this.state.FooterLinks, twitter: responseJson.data.logo_url.url
                }
            })
        }
    }

    GetResponseInstagramApiCall = (apiRequestCallId:string, responseJson:SocialLinkApiResonse ) =>{
        if (apiRequestCallId === this.InstagramApiCallId) {
            this.setState({
                FooterLinks:{
                    ...this.state.FooterLinks, instagram: responseJson.data.logo_url.url
                }
            })
        }
    }

    GetResponseLinkedinApiCall = (apiRequestCallId:string, responseJson:SocialLinkApiResonse ) =>{
        if (apiRequestCallId === this.LinkedinApiCallId) {
            this.setState({
                FooterLinks:{
                    ...this.state.FooterLinks, linkedin: responseJson.data.logo_url.url
                }
            })
        }
    }

    GetResponseFacebookApiCall = (apiRequestCallId:string, responseJson:SocialLinkApiResonse ) =>{
        if (apiRequestCallId === this.FacebookApiCallId) {
            this.setState({
                FooterLinks:{
                    ...this.state.FooterLinks, facebook: responseJson.data.logo_url.url
                }
            })
        }
    }

    async componentDidMount() {
        this.GetGoogleDataApiCallFun()
        this.GetFaceBookDataApiCallFun()
        this.GetLinkedinDataApiCallFun()
        this.GetTwitterDataApiCallFun()
        this.GetInstagramDataApiCallFun()
    }

    navigateToAnyPage = (pageName:string) => {
      setStorageData("LandingPageActive",pageName)
        this.props.onAnyPageNavigate(pageName)
    }

    FooterApiCall = async (apiData: APIPayloadType) => {
        const { contentType, method, body, type, endPoint } = apiData;
        let token = await getStorageData("token")
        const header = {
          "Content-Type": contentType,
          token: token
        };
        const requestMessageFooter = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageFooter.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessageFooter.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessageFooter.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
    
        body && type !== "formData"
          ? requestMessageFooter.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
          : requestMessageFooter.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
    
        runEngine.sendMessage(requestMessageFooter.id, requestMessageFooter);
    
        return requestMessageFooter.messageId;
    };

    GetGoogleDataApiCallFun = async () => {
        this.GoogleApiCallId = await this.FooterApiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/account_block/social_links/get_google_link"
        });
    }
    GetFaceBookDataApiCallFun = async () => {
        this.FacebookApiCallId = await this.FooterApiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/account_block/social_links/get_facebook_link"
        });
    }

    GetLinkedinDataApiCallFun = async () => {
        this.LinkedinApiCallId = await this.FooterApiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/account_block/social_links/get_linkedin_link"
        });
    }
    GetTwitterDataApiCallFun = async () => {
        this.TwitterApiCallId = await this.FooterApiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/account_block/social_links/get_twitter_link"
        });
    }
    GetInstagramDataApiCallFun = async () => {
        this.InstagramApiCallId = await this.FooterApiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/account_block/social_links/get_insta_link"
        });
    }



    render() {
        return (
            <FooterMainBox>
                <Grid container spacing={3}>
                    <FooterGrid item xs={12} lg={4} md={4} sm={4}>
                        <FirstBox >
                            <LogoContainer>
                                <FooterImg
                                    src={IMG_CONST.footerLogoImg}
                                />
                                <AddressTxt>101 Frederick St,Kitchener, ONN2H 6R3</AddressTxt>
                            </LogoContainer>
                        </FirstBox>
                    </FooterGrid>
                    <FooterGrid item xs={12} lg={4} md={4} sm={4} style={{justifyContent:'center'}}>
                        <CenterBox>
              <ItemTxt onClick={() => this.props.renderButtonClick?.("agent")}>For Agent</ItemTxt>
              <ItemTxt onClick={() => this.props.renderButtonClick?.("students")}>For Student</ItemTxt>
              <ItemTxt onClick={() => this.props.renderButtonClick?.("institutions")}>Institutions</ItemTxt>
              <ItemTxt onClick={() => this.props.renderButtonClick?.("aboutUs")}>About Us</ItemTxt>
              <ItemTxt onClick={() => this.props.renderButtonClick?.("contact")}>Contact</ItemTxt>
            </CenterBox>
                    </FooterGrid>
                    <FooterGrid item xs={12} lg={4} md={4} sm={4}>
                        <LastBox>
                            <ItemTxt onClick={()=>this.navigateToAnyPage("PrivacyPolicyWeb")}>Privacy & Cookies Policy</ItemTxt>
                            <ItemTxt onClick={()=>this.navigateToAnyPage("TermsConditionsWeb")}>Terms & Conditions</ItemTxt>
                        </LastBox>
                    </FooterGrid>
                    <FooterGrid item xs={12} lg={12} md={3} sm={3} style={{borderTop:'1px solid #334155',padding:'30px 12px 20px 12px'}}>
                        <GetSocialContainer>
                            <Box className="headBox"> <Typography>Get Social</Typography></Box>
                            <Box className="socialIconBox">
                                <SocialButton  target='_blank' data-test-id="FacebookLink" href={this.state.FooterLinks.facebook}>
                                    <SocialImg
                                        src={this.state.FooterLinks.facebook ? this.state.FooterLinks.facebook :IMG_CONST.image_Facebook}
                                    />
                                </SocialButton>
                                <SocialButton target='_blank' data-test-id="GoogleId" href={this.state.FooterLinks.google}>
                                    <SocialImg
                                        src={this.state.FooterLinks.google ? this.state.FooterLinks.google :IMG_CONST.button_Google}
                                    />
                                </SocialButton>
                                <SocialButton target='_blank' href={this.state.FooterLinks.linkedin}>
                                    <SocialImg
                                        src={this.state.FooterLinks.linkedin ? this.state.FooterLinks.linkedin :IMG_CONST.button_LinkedIn}
                                    />
                                </SocialButton>
                                <SocialButton target='_blank' href={this.state.FooterLinks.twitter}>
                                    <SocialImg
                                        src={this.state.FooterLinks.twitter ? this.state.FooterLinks.twitter :IMG_CONST.button_Twitter}
                                    />
                                </SocialButton>
                                <SocialButton target='_blank' href={this.state.FooterLinks.instagram}>
                                    <SocialImg
                                        src={this.state.FooterLinks.instagram ? this.state.FooterLinks.instagram :IMG_CONST.button_Instagram}
                                    />
                                </SocialButton>
                            </Box>
                        </GetSocialContainer>
                    </FooterGrid>
                </Grid>
            </FooterMainBox>
        )
    }
}

const FooterMainBox = styled(Box)({
    background: "#1E293B",
    padding: "30px 120px 40px 120px",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Plus Jakarta Sans",
    "@media (max-width: 600px)": {
        padding: "30px 80px 40px 80px",
    },
    "@media (max-width: 400px)": {
        padding: "30px 40px 40px 40px",
    },
});

const FooterGrid = styled(Grid)({
    display: "flex",
    justifyContent: "flex-start"
});

const ItemTxt = styled(Typography)({
    color: "#fff",
    textAlign: "center",
    margin: "0px 0px 15px 0px",
    cursor: "pointer"
});
const AddressTxt = styled(Typography)({
    maxWidth: "150px",
    minWidth: "50px",
    color: "#fff",
    fontSize: "14px",
    "@media(max-width:576px)": {
        maxWidth: "100%"
    }
});
const SocialImg = styled("img")({
    height: "20px",
    width: "20px",
}); const SocialButton = styled("a")({
    minWidth: "0px",
    marginRight:'20px',
    "&..MuiButton-text": {
        padding: "0px",
    },
    "&.MuiButton-root:hover": {
        backgroundColor: "transparent",
    },
});

const FooterImg = styled("img")({
    height: "80px",
    borderRadius: "15px",
    width: "80%",
    margin: "0px 0px 20px 0px"
});

const CenterBox = styled(Box)({
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "100%",
    "& .MuiTypography-body1": {
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: 400,
        fontStyle: "Plus Jakarta Sans"
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#ffff",
            margin: "0px 0px 15px 0px"
        }
    }
});
const FirstBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    "& .MuiTypography-body1": {
        color: "#fff",
        cursor: "pointer",
        margin: "5px",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Plus Jakarta Sans"
    },
    "@media(max-width:576px)": {
        alignItems: "center",
    }
});

const LogoContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media(max-width:576px)": {
        alignItems: "center"
    }
})

const LastBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems:'flex-start',
    height: "100%",
    "& .MuiTypography-body1": {
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: 400,
        fontStyle: "Plus Jakarta Sans"
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#ffff",
            margin: "0px 0px 15px 0px"
        }
    }
});

const GetSocialContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems:'baseline',
    paddingLeft: "2rem",
    "& .socialIconBox": {
        display: "flex",
        flexWrap: "nowrap"
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#ffff",
            margin: "0px 20px 0px 0px",
            whiteSpace:"nowrap",
            "@media(max-width:700px)": {
                whiteSpace:"normal",
                marginRight:'0',
            }
        }
    },
    "@media(max-width:576px)": {
        width: "100%",
        alignItems: "center",
        marginTop: "0px",
        paddingLeft: "0px"
    }
})
