// Customizable Area Start
import React from "react";

import { Box, FormControl, FormControlLabel,styled, Grid, MenuItem, Radio, RadioGroup, Step, StepConnector, StepIconProps, StepLabel, Stepper, TextField, Typography, Select, Button, RadioProps, Container, TextareaAutosize, Modal, SvgIcon } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
export const configJSON = require("./config");
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import FormApprovalWorkflowController, {
  Props,
} from "./FormApprovalWorkflowController.web";
import { Check, CheckCircle, DeleteForever, KeyboardArrowDown } from "@material-ui/icons";
import { DragAndDrop, successTick } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import { Bounce, ToastContainer } from "react-toastify";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Prompt } from 'react-router'
import { Autocomplete } from "@mui/material";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  
  typography: {
    h6: {
      fontWeight: 500,
    },
    fontFamily: "Plus Jakarta Sans",
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class FormApprovalWorkflow extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }

  QontoStepIcon(props:StepIconProps) {
    const { completed } = props;
  
    return (
      <div>
        {completed ? <ActiveConnecter /> : <DeactiveConnecter />}
      </div>
    );
  }


  getContactError = () => {
    const { contactNumberError, contactNumberErrorTxt } = this.state;
    return contactNumberError ? <RequiredTxt>{contactNumberErrorTxt}</RequiredTxt> : null;
  }

  getCityNameError = () => {
    const { cityNameError, cityNameErrorTxt } = this.state;
    return cityNameError ? <RequiredTxt>{cityNameErrorTxt}</RequiredTxt> : null;
  }

  getStateNameError = () => {
    const { stateError, stateErrorTxt } = this.state;
    return stateError ? <RequiredTxt>{stateErrorTxt}</RequiredTxt> : null;
  }

  getCountryError = () => {
    const { countryError, countryErrorTxt } = this.state;
    return countryError ? <RequiredTxt>{countryErrorTxt}</RequiredTxt> : null;
  }

  getCountryErrorRes = () => {
    const { countryResError, countryResErrorTxt } = this.state;
    return countryResError ? <RequiredTxt>{countryResErrorTxt}</RequiredTxt> : null;
  }
  
  errorCounter = (touched:string | boolean | undefined, errors:string | undefined) =>{
    return errors && touched && (
        <RequiredTxt>{errors}</RequiredTxt>
      )
  }
  
  personalInformationForm = () => {
    const isCounceller = this.state?.userDetails?.attributes?.role==="counsellor"
    return (
      <Grid container
      direction="row"
      justifyContent="center"
      alignContent="center"
      style={webStyle.gapTwelve}>
        <Grid item xs={12} style={webStyle.justifyCenter}>
  <HeaderTypography data-test-id="personalInfoTestID">{configJSON.personalInformation}</HeaderTypography>
</Grid>
<Container maxWidth="sm">
<Formik
initialValues={{
  firstName: this.state.personalInformation.firstName || "",
  lastName: this.state.personalInformation.lastName || "",
  gender: this.state.personalInformation.gender || "",
  maritalStatus: this.state.personalInformation.maritalStatus || "",
  email: this.state.personalInformation.email.trim() || "",
  passport: this.state.personalInformation.passport || "",
  associateBranchName: this.state.personalInformation.associateBranchName || ""
}}
data-test-id="formik"
validationSchema={Yup.object().shape({
  firstName: Yup.string()
    .required("*First Name is required"),
    lastName: Yup.string()
    .required("*Last Name is required"),
  gender: Yup.string().required("*Gender is required"),
  maritalStatus: Yup.string()
    .required("*Marital Status is required"),
  email:Yup.string().trim().email("*Please enter valid Email")
  .required("*Email is required"),
  passport:Yup.string().matches(configJSON.passPortRegex,configJSON.passportValidationMsg)
  .required("*Passport is required"),
  associateBranchName: Yup.string()
      .when([], {
        is: () => !isCounceller,
        then: (schema: Yup.StringSchema) => schema.required("*Associated Branch is required"),
        otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
      }),
})}
onSubmit={async (values) => {
  const isValidNumber =  this.state.contactNumberErrorTxt!==configJSON.invalidMobileNumberErrorText&& this.state.contactNumber;
  if(!this.state.cityNameError &&isValidNumber && !this.state.stateError && !this.state.countryError){
      this.nextButtonClick();
      this.setState({personalInformation:values})
  }
}}>
  {({handleSubmit,errors,handleChange,values,touched})=>{
    this.NavigationTextCondiation(values)
    return (
    <form onSubmit={handleSubmit}>
<Grid container>
<PersonalInformationGrid item xs={12} md={6}>
<InputMainBox>
            <InputTxtHeader>{configJSON.labelFirstName}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxtHeader>
            <InputField
            name="firstName"
            data-test-id="firstName"
              placeholder={configJSON.labelFirstName}
              value={values.firstName}
            onKeyPress={this.disableNumericValue}
              onChange={handleChange}
            />
              {this.errorCounter(touched.firstName ,errors.firstName)}
          </InputMainBox>
</PersonalInformationGrid>
<PersonalInformationGrid item xs={12} md={6}>
          <InputMainBox>
            <InputTxtHeader>{configJSON.lastName}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxtHeader>
            <InputField
            name="lastName"
            onKeyPress={this.disableNumericValue}
            data-test-id="lastName"
              placeholder={configJSON.lastName}
              value={values.lastName}
              onChange={handleChange}
            />
              {this.errorCounter(touched.lastName ,errors.lastName)}
          </InputMainBox>
</PersonalInformationGrid>
<PersonalInformationGrid item xs={12}>
<InputMainBox>
<FormControl fullWidth>
<InputTxt>{configJSON.gender}<StarTxt> {configJSON.starTxt}</StarTxt></InputTxt>
      <CusomiseRadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue={values.gender}
      >
        <FormControlLabel data-test-id="genderTestId" name="gender" value="Male" control={<RadioButton />} label="Male" onChange={handleChange}/>
        <FormControlLabel name="gender" value="Female" control={<RadioButton />} label="Female" onChange={handleChange}/>
      </CusomiseRadioGroup>
    </FormControl>
              {this.errorCounter(touched.gender ,errors.gender)}
    </InputMainBox>
</PersonalInformationGrid>

<PersonalInformationGrid item xs={12}>
<InputMainBox>
<FormControl fullWidth>
<InputTxt>{configJSON.maritalStatus}<StarTxt> {configJSON.starTxt}</StarTxt></InputTxt>
      <CusomiseRadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group1"
        defaultValue={values.maritalStatus}
      >
        <FormControlLabel name="maritalStatus" value="married" control={<RadioButton />} label="Married"  onChange={handleChange}/>
        <FormControlLabel data-test-id="maritalStatusTestId" name="maritalStatus" value="unmarried" control={<RadioButton />} label="Unmarried" onChange={handleChange}/>
      </CusomiseRadioGroup>
    </FormControl>
        {this.errorCounter(touched.maritalStatus ,errors.maritalStatus)}
    </InputMainBox>
</PersonalInformationGrid>

<PersonalInformationGrid item xs={12}>
  <InputMainBox>
    <InputTxt>
      {configJSON.country_national}
      <StarTxt> {configJSON.starTxt}</StarTxt>
    </InputTxt>
    <Autocomplete
      id="country-autocomplete"
      className="countrySelect"
      options={this.state.countryData}
      getOptionLabel={(option:any) => option.name || ""}
      value={
        this.state.countryData.find(
          (item: { isoCode: any; }) => item.isoCode === this.state.countryValue_nation
        ) || null
      }
      onChange={(event, newValue) => {
        {console.log(newValue,'neww')}
        this.setState({ countryValue_nation: newValue ? newValue.isoCode : "none" });
        this.handleCountryChange_nation(event, newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={configJSON.country_national_placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {/* <KeyboardArrowDown /> */}
              </>
            ),
          }}
        />
      )}
      style={{
        width: "100%",
      }}
      ListboxProps={{
        style: {
          maxHeight: "200px", // Set max height for the dropdown
          overflow: "auto", // Add scrolling if needed
        },
      }}
      data-test-id="countryDataTestId"
    />
    {this.getCountryError()}
  </InputMainBox>
</PersonalInformationGrid>


<PersonalInformationGrid item xs={12}>
  <div style={{display:'none'}}>
    <InputMainBox>
    <button onClick={this.backButtonClickCustom2}></button>
  </InputMainBox>
  </div>
  <InputMainBox>
    <InputTxt>
      {configJSON.country_residence}
      <StarTxt> {configJSON.starTxt}</StarTxt>
    </InputTxt>
    <Autocomplete
      id="country-autocomplete-res"
      className="countrySelect"
      options={this.state.countryData} // Array of countries
      getOptionLabel={(option: any) => option.name || ""} // Label for dropdown items
      value={
        this.state.countryData.find(
          (item:any) => item.isoCode === this.state.countryValue_res
        ) || null
      } // Current selected value
      onChange={(event, newValue) => {
        {console.log(newValue,'newww2')}
        {console.log(newValue,'newww5')}
        {console.log(newValue,'newww3')}
        {console.log(newValue,'newww2')}
        this.setState({
          countryValue_res: newValue ? newValue.isoCode : "none",
        });
        {console.log(this.state.countryValue,'countryy')}
        this.handleCountryChange_res(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={configJSON.country_residency_placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {/* <KeyboardArrowDown /> */}
              </>
            ),
          }}
        />
      )}
      style={{
        width: "100%",
      }}
      ListboxProps={{
        style: {
          maxHeight: "200px", // Set max height for dropdown
          overflow: "auto", // Enable scrolling if needed
        },
      }}
      data-test-id="countryDataTestId_res"
    />
    {this.getCountryErrorRes()}
  </InputMainBox>
</PersonalInformationGrid>

<PersonalInformationGrid item xs={12}>
<InputMainBox>
            <InputTxt>{configJSON.selectState}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                disableScrollLock: true,
              }}
              style={{
                color: this.state.stateValue == "none" ? "#999" : "#000"
              }}
              value={this.state.stateValue}
              data-test-id="stateDataTestId"
              IconComponent={KeyboardArrowDown}
              defaultValue="default"
              onChange={this.handleStateChange}
              disabled={this.state.countryValue_res == "none" ? true : false }
            >
              {(this.state.stateValue) == "none" && (
                <MenuItem disabled value="none">
                  {configJSON.selectStatePlaceholderTxt}
                </MenuItem>
              )}
              {this.state.stateData.map((item: { name: string }) => (
                <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
              ))}
            </SelectInput>
            {this.getStateNameError()}
          </InputMainBox>
</PersonalInformationGrid>
<PersonalInformationGrid item xs={12}>
<InputMainBox>
            <InputTxt>{configJSON.selectCity}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
              placeholder={configJSON.cityPlaceholderTxt}
              onChange={this.handleCityChange}
              onKeyPress={this.disableNumericValue}
              value={this.state.cityName}
              data-test-id="cityDataTestId"
            />
            {this.getCityNameError()}
          </InputMainBox>
</PersonalInformationGrid>

<PersonalInformationGrid item xs={12}>
          <InputMainBox>
            <InputTxt>
            {configJSON.passportFieldName}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt><InputField name="passport"
            data-test-id="passportId" placeholder={configJSON.passportPlaceholderName}
              value={values.passport}
              onChange={handleChange}
              onBlur={this.validatePassportNo}
            />
              {this.errorCounter(touched.passport ,errors.passport)}
              {this.state.isPassportErrorValid && (<RequiredTxt>{this.state.passportErrorMessage}</RequiredTxt>)}
          </InputMainBox>
</PersonalInformationGrid>

<PersonalInformationGrid item xs={12}>
          <InputMainBox>
            <InputTxt>
            {configJSON.associateBranchFieldName}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  horizontal: "left",
                  vertical: "bottom"
                },
              }}
              name="associateBranchName"
              style={{
                color: this.state.stateValue == "none" ? "#999" : "#000"
              }}
              value={values.associateBranchName}
              IconComponent={KeyboardArrowDown}
              data-test-id="associateBranchNameId"
              defaultValue="default"
              onChange={handleChange}
            >
                {values.associateBranchName === "" && (
                  <MenuItem value="" disabled>
                    {configJSON.associateBranchNamePlaceholderName}
                  </MenuItem>
                )}
                {this.state.AssociateName.map((item) => {
                  return <MenuItem value={item.name}>{item.name}</MenuItem>
                })}
            </SelectInput>
              {this.errorCounter(touched.associateBranchName ,errors.associateBranchName)}
          </InputMainBox>
</PersonalInformationGrid>

<PersonalInformationGrid item xs={12}>
<InputMainBox>
              <InputTxt>
                {configJSON.mobile}<StarTxt> {configJSON.starTxt}</StarTxt>
              </InputTxt>
              <Box style={webStyle.mobileNumberWrapper}>
                  <Box>
                  <SelectInput
              displayEmpty
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  horizontal: "left",
                  vertical: "bottom"
                },
              }}
              style={{
                color: this.state.stateValue == "none" ? "#999" : "#000"
              }}
              value={this.state.contryCodeValue}
              IconComponent={KeyboardArrowDown}
              data-test-id="stateCountryCodeTestId"
              defaultValue="default"
              onChange={this.handleCountryCodeChange}
            >
                {this.state.countryCodes.map((item) => (
                <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>{`+${item.country_code} (${item.alpha2})`}</MenuItem>
              ))}
            </SelectInput>
                  </Box>
                  <Box style={webStyle.mobileNumberBox}>
                    <InputField
                    type="tel"
            name="number"
            data-test-id="contactNumberTestId"
            value={this.state.contactNumber}
            onChange={this.handleContactNumberChange}
            placeholder={configJSON.mobilePlaceholderTxt}
            onBlur={this.validateMobileNo}
            />
                  </Box>
              </Box>
              {this.getContactError()}
</InputMainBox>
          </PersonalInformationGrid>
<PersonalInformationGrid item xs={12}>
<InputMainBox>
            <InputTxt>{configJSON.email}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
            name="email"
            data-test-id="email"
            value={values.email}
            onChange={handleChange}
              placeholder={configJSON.emailPlaceholderTxt}
              onBlur={this.validatePersonalEmail}
            />
              {this.errorCounter(touched.email ,errors.email)}
               {this.state.isEmailErrorValid && (<RequiredTxt data-test-id="emailError">{this.state.emailErrorMessage}</RequiredTxt>)}
          </InputMainBox>
</PersonalInformationGrid>
</Grid>
<Grid item xs={12} style={webStyle.paddingXTwenty}>
      <ButtonBox>
        <BackButton data-test-id="backBtnTestIdBack" onClick={this.backButtonClickCustom}>
          <BackButtonTxt>{configJSON.back}</BackButtonTxt>
        </BackButton>
        <NextButton data-test-id="nextBtnTestId" type="submit" onClick={this.checkFieldError}>
          <NextButtonTxt>{configJSON.next}</NextButtonTxt>
        </NextButton>
      </ButtonBox>
</Grid>
    </form>
  )}}
</Formik>
</Container>
      </Grid>
    )
  }

  educationDetails = () => {
    return (
      <Grid container
        direction="row"
        justifyContent="center"
        alignContent="center"
        style={webStyle.gapTwelve}>

        <Grid item xs={12} style={webStyle.justifyCenter}>
          <HeaderTypography>{configJSON.educationDetail}</HeaderTypography>
        </Grid>
        <Grid
          item xs={12}
          style={webStyle.flexAndGap} direction="column"
          justifyContent="center" alignItems="center"
        >
          <DragAndDropDescription>{configJSON.academicsText}
          </DragAndDropDescription>
          <FileUploadRule>
            <FileUploadRuleBox><strong>{configJSON.acceptedFileFormatText}</strong>{configJSON.fileType}
            </FileUploadRuleBox>
            <FileUploadRuleBox>
              <strong>{configJSON.acceptedFileSizeText}</strong>{configJSON.aceeptFileSize}</FileUploadRuleBox>
          </FileUploadRule>
        </Grid>
        <Grid item xs={12}
          style={webStyle.flexAndGap}
          direction="column" justifyContent="center"
          alignItems="center">
          {this.state.educationDetailsFile.map((emerFile, index) => <UploadImageBox>
            <Typography style={webStyle.defaultText}>{emerFile?.name}</Typography>
            <Box data-test-id="remove-education-file" onClick={() => this.removeEducationFile(index)} style={webStyle.DeleteIconWrapper}>
              <SvgIcon component={DeleteOutlineIcon} style={{ color: 'red' }} />
              <DeleteIcon>{configJSON.deleteFileUploaded}</DeleteIcon>
            </Box>
            </UploadImageBox>)
          }
          <UploadImageBox >
            <FileUploadLabel htmlFor="educationDocFile">
              <Box style={webStyle.uploadimageBox}>
                <img src={DragAndDrop} height={"100%"} width={"100%"} />
              </Box>
              <Typography>{configJSON.dragFile}</Typography>
              <Typography>{configJSON.or}</Typography>
              <input hidden multiple type="file" id="educationDocFile" data-test-id="educationDetailsFileId" placeholder="Choose a File To Upload" onChange={this.handleEducationFileChange}/>
              <FileUploadTxtBox>
                <Typography style={webStyle.defaultText}>{configJSON.chooseFile}</Typography>
              </FileUploadTxtBox>
            </FileUploadLabel>
            {this.state.educationDetailsError && <RequiredTxt>{this.state.educationDetailsErrorTxt}</RequiredTxt>}
          </UploadImageBox>
          </Grid>
        <Grid item xs={12} style={webStyle.paddingXTwenty}>
          <ButtonBox>
            <BackButton data-test-id="backBtnTestId" onClick={this.backButtonClick}>
              <BackButtonTxt>{configJSON.back}</BackButtonTxt>
            </BackButton>
            <NextButton data-test-id="nextBtnTestId" onClick={this.verifyEducationDetails}>
              <NextButtonTxt>{configJSON.next}</NextButtonTxt>
            </NextButton>
          </ButtonBox>
        </Grid>
      </Grid>
    )
  }

  englishTestScore = () => {
    return (
      <Grid container
        direction="row"
        justifyContent="center"
        alignContent="center"
        style={webStyle.gapTwelve}>
        <Grid
          item
          xs={12}
          style={webStyle.justifyCenter}
        >
          <HeaderTypography>{configJSON.englishTestScore}</HeaderTypography>
        </Grid>
        <Grid item xs={12}
          style={webStyle.flexAndGap}
          direction="column"
          justifyContent="center"
          alignItems="center">
          <DragAndDropDescription>
            {configJSON.englishText}
          </DragAndDropDescription>
          <FileUploadRule>
            <FileUploadRuleBox><strong>{configJSON.acceptedFileFormatText}</strong>{configJSON.fileType}
            </FileUploadRuleBox>
            <FileUploadRuleBox>
              <strong>{configJSON.acceptedFileSizeText}</strong>{configJSON.aceeptFileSize}</FileUploadRuleBox>
          </FileUploadRule>
        </Grid>

        <Grid item xs={12}
          style={webStyle.flexAndGap} direction="column"
          justifyContent="center" alignItems="center">
          {this.state.englishTestScoreFile.map((emerFile, index) => <UploadImageBox>
            <Typography style={webStyle.defaultText}>{emerFile?.name}</Typography>
            <Box data-test-id="remove-english-file" onClick={() => this.removeEnglishTestScoreFile(index)} style={webStyle.DeleteIconWrapper}>
              <SvgIcon component={DeleteOutlineIcon} style={{ color: 'red' }} />
              <DeleteIcon>{configJSON.deleteFileUploaded}</DeleteIcon>
            </Box>
          </UploadImageBox>)
          }
          <UploadImageBox >
            <FileUploadLabel htmlFor="englishDocFile">
              <Box style={webStyle.uploadimageBox}>
                <img src={DragAndDrop} height={"100%"} width={"100%"} />
              </Box>
              <Typography>{configJSON.dragFile}</Typography>
              <Typography>{configJSON.or}</Typography>
              <input hidden multiple type="file" id="englishDocFile" data-test-id="englishTestScoreFileId" placeholder="Choose a File To Upload" onChange={this.handleEnglishFileChange} />
              <FileUploadTxtBox>
                <Typography style={webStyle.defaultText}>{configJSON.chooseFile}</Typography>
              </FileUploadTxtBox>
            </FileUploadLabel>
            {this.state.englishTestScoreError && <RequiredTxt>{this.state.englishTestScoreErrorTxt}</RequiredTxt>}
          </UploadImageBox>
        </Grid>

        <Grid item xs={12} style={webStyle.paddingXTwenty}>
          <ButtonBox>
            <BackButton data-test-id="backBtnTestId" onClick={this.backButtonClick}>
              <BackButtonTxt>{configJSON.back}</BackButtonTxt>
            </BackButton>
            <NextButton data-test-id="nextBtnTestId" onClick={this.verifyEnglishTestScore}>
              <NextButtonTxt>{configJSON.next}</NextButtonTxt>
            </NextButton>
          </ButtonBox>
        </Grid></Grid>
    )
  }

  workExperience = () => {
    return (
      <Grid container
        direction="row"
        justifyContent="center"
        alignContent="center"
        style={webStyle.gapTwelve}>
        <Grid item xs={12} style={webStyle.justifyCenter}><HeaderTypography>{configJSON.workExperience}</HeaderTypography></Grid>
        <Grid item xs={12} style={webStyle.flexAndGap} direction="column" justifyContent="center" alignItems="center">
          <DragAndDropDescription>{configJSON.workExperienceTxt}</DragAndDropDescription>
          <FileUploadRule>
            <FileUploadRuleBox><strong>{configJSON.acceptedFileFormatText}</strong>{configJSON.fileType}
            </FileUploadRuleBox>
            <FileUploadRuleBox>
              <strong>{configJSON.acceptedFileSizeText}</strong>{configJSON.aceeptFileSize}</FileUploadRuleBox>
          </FileUploadRule>
        </Grid>
        <Grid item xs={12} style={webStyle.flexAndGap} direction="column" justifyContent="center" alignItems="center">
          {this.state.workExperienceFile.map((emerFile, index) => <UploadImageBox>
            <Typography style={webStyle.defaultText}>{emerFile?.name}</Typography>
            <Box data-test-id="remove-work-file" onClick={() => this.removeWorkExperienceFile(index)} style={webStyle.DeleteIconWrapper}>
              <SvgIcon component={DeleteOutlineIcon} style={{ color: 'red' }} />
              <DeleteIcon>{configJSON.deleteFileUploaded}</DeleteIcon>
            </Box>
          </UploadImageBox>)
          }
          <UploadImageBox >
            <FileUploadLabel htmlFor="workDocFile">
              <Box style={webStyle.uploadimageBox}>
                <img src={DragAndDrop} height={"100%"} width={"100%"} />
              </Box>
              <Typography>{configJSON.dragFile}</Typography>
              <Typography>{configJSON.or}</Typography>
              <input hidden multiple type="file" id="workDocFile" data-test-id="workkExperienceFileId" placeholder="Choose a File To Upload" onChange={this.handleWorkExperienceFileChange}/>
              <FileUploadTxtBox>
                <Typography style={webStyle.defaultText}>{configJSON.chooseFile}</Typography>
              </FileUploadTxtBox>
            </FileUploadLabel>
            {this.state.workExperienceError && <RequiredTxt>{this.state.workExperienceErrorTxt}</RequiredTxt>}
          </UploadImageBox>
        </Grid>
        <Grid item xs={12} style={webStyle.paddingXTwenty}>
          <ButtonBox>
            <BackButton data-test-id="backBtnTestId" onClick={this.backButtonClick}>
              <BackButtonTxt>{configJSON.back}</BackButtonTxt>
            </BackButton>
            <NextButton data-test-id="nextBtnTestId" onClick={this.verifyWorkExperience}>
              <NextButtonTxt>{configJSON.next}</NextButtonTxt>
            </NextButton>
          </ButtonBox>
        </Grid>
      </Grid>
    )
  }

  passportAndTravels = () => {
    const today = new Date();
    const hundredYearsAgo = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
    const maxDate = today.toISOString().split('T')[0];
    const minDate = hundredYearsAgo.toISOString().split('T')[0];

    return (
      <Grid container
        direction="row"
        justifyContent="center"
        alignContent="center"
        style={webStyle.gapTwelve}>

        <Grid item xs={12} style={webStyle.justifyCenter}>
          <HeaderTypography
          >{configJSON.passportHistory}
          </HeaderTypography>
        </Grid>
        <Grid item
          xs={12}
          style={webStyle.flexAndGap} direction="column"
          justifyContent="center"
          alignItems="center">
          <DragAndDropDescription>{configJSON.fillingFormIsLengthy}</DragAndDropDescription>

          <FileUploadRule>
            <FileUploadRuleBox><strong>{configJSON.acceptedFileFormatText}</strong>{configJSON.fileType}
            </FileUploadRuleBox>
            <FileUploadRuleBox>
              <strong>{configJSON.acceptedFileSizeText}</strong>{configJSON.aceeptFileSize}</FileUploadRuleBox>
          </FileUploadRule>

        </Grid>
        <Grid item xs={12} style={webStyle.flexAndGap}
          direction="column" justifyContent="center" alignItems="center">
          {this.state.passportFile.map((emerFile, index) => <UploadImageBox>
            <>
              <Typography style={webStyle.defaultText}>{emerFile?.name}</Typography>
              <Box data-test-id="remove-personal-file" onClick={() => this.removePassportFile(index)} style={webStyle.DeleteIconWrapper}>
                <SvgIcon component={DeleteOutlineIcon} style={{ color: 'red' }} />
                <DeleteIcon>
                  {configJSON.deleteFileUploaded}
                </DeleteIcon>
              </Box>
            </>
          </UploadImageBox>)
          }

          <UploadImageBox >
            <FileUploadLabel htmlFor="passportDocFile">
              <Box style={webStyle.uploadimageBox}>
                <img src={DragAndDrop} height={"100%"} width={"100%"} />
              </Box>
              <Typography>{configJSON.dragFile}</Typography>
              <Typography>{configJSON.or}</Typography>
              <input hidden multiple type="file" id="passportDocFile" data-test-id="passportFileId" placeholder="Choose a File To Upload"
                onChange={this.handlePassportFileChange}
              />
              <FileUploadTxtBox>
                <Typography style={webStyle.defaultText}>{configJSON.chooseFile}</Typography>
              </FileUploadTxtBox>
            </FileUploadLabel>
            {this.state.passportError && (<RequiredTxt>{this.state.passportErrorTxt}</RequiredTxt>)}
          </UploadImageBox>
        </Grid>

        <Grid container key={'key'}>
          <Grid item xs={12} style={webStyle.justifyCenter}>
            <HeaderTypography key={configJSON.visaRefusal}>{configJSON.visaRefusal}</HeaderTypography>
          </Grid>
          {this.state.passportAndTravelHistory.visaRefusals.map((visa, index) => (
            <>
              <PersonalInformationGrid key={index} item xs={12} md={6}>
                <InputMainBox>
                  <InputTxt>{configJSON.country}</InputTxt>
                  <Autocomplete
                    id="country-autocomplete"
                    className="countrySelect"
                    options={this.state.countryData}
                    getOptionLabel={(option: any) => option.name || ""}
                    value={
                      this.state.countryData.find(
                        (item: { isoCode: any }) => item.isoCode === visa.country
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      this.addVisaRefusalCountry(event, index, newValue ? newValue.isoCode : "none");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={configJSON.country_national_placeholder}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,

                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                        overflow: "auto",
                      },
                    }}
                    data-test-id="visaCountryId"
                  />

                </InputMainBox>
              </PersonalInformationGrid>
              <PersonalInformationGrid item xs={12} md={6}>
                <Box style={webStyle.flexCenter}>
                  <InputMainBox>
                    <InputTxt>{configJSON.date}
                    </InputTxt>
                    <InputField
                      type="date"
                      data-test-id="travelDateId"
                      value={visa.date}
                      onChange={(event) => this.addVisaRefusalDate(event, index)}
                      placeholder={configJSON.date}
                      inputProps={{ min: minDate, max: maxDate }}
                    />

                  </InputMainBox>
                  <Box marginTop="20px">
                    <Button onClick={() => this.removeVisaRefusalFields(index)}>
                      <DeleteForever />
                    </Button>
                  </Box>
                </Box>
              </PersonalInformationGrid>
            </>
          ))}
          <PersonalInformationGrid item xs={12}>
            <Typography data-test-id="addVisaFieldsId" style={webStyle.themeColorAndCursor} onClick={this.addVisaRefusalFields}>
              {configJSON.addAnother}
            </Typography>
          </PersonalInformationGrid>
          <Grid item xs={12} style={webStyle.justifyCenter}>
            <HeaderTypography>{configJSON.travelHistoryTxt}</HeaderTypography>
          </Grid>
          {this.state.passportAndTravelHistory.travelHistories.map((travel, index) => (
            <>
              <PersonalInformationGrid item xs={12} md={6}>
                <InputMainBox>
                  <InputTxt>{configJSON.country}</InputTxt>

                  <Autocomplete
                    id="country-autocomplete"
                    className="countrySelect"
                    options={this.state.countryData}
                    getOptionLabel={(option: any) => option.name || ""}
                    value={
                      this.state.countryData.find(
                        (item: { isoCode: any }) => item.isoCode === travel.country
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      this.addTravelHistoryCountry(event, index, newValue ? newValue.isoCode : "none");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={configJSON.country_national_placeholder}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,

                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                        overflow: "auto",
                      },
                    }}
                    data-test-id="travelhistoryId"
                  />

                </InputMainBox>
              </PersonalInformationGrid>
              <PersonalInformationGrid
                item xs={12} md={6}>
                <Box style={webStyle.flexCenter}>
                  <InputMainBox><InputTxt>{configJSON.date}</InputTxt>
                    <DatePicker
                      selected={travel.from_travel_date ? moment(travel.from_travel_date).toDate() : null}
                      onChange={(dates) => this.handleDateChange(dates, index)}
                      startDate={travel.from_travel_date ? moment(travel.from_travel_date).toDate() : null}
                      endDate={travel.to_travel_date ? moment(travel.to_travel_date).toDate() : null}
                      selectsRange
                      customInput={<this.CustomInput />}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      data-test-id="startDateId"
                      className="selectInput"
                    />
                  </InputMainBox>
                  <Box marginTop="20px">
                    <Button onClick={() => this.removeTravelhistory(index)}>
                      <DeleteForever />
                    </Button>
                  </Box>
                </Box>
              </PersonalInformationGrid>
            </>
          ))}
          <PersonalInformationGrid item xs={12}>
            <Typography data-test-id="addTouristFieldsId" style={webStyle.themeColorAndCursor} onClick={this.addTravelHistoryFields}>
              {configJSON.addAnother}
            </Typography>
          </PersonalInformationGrid>
        </Grid>
        <Grid item xs={12} style={webStyle.paddingXTwenty}>
          <ButtonBox>
            <BackButton data-test-id="backBtnTestId" onClick={this.backButtonClick}>
              <BackButtonTxt>{configJSON.back}</BackButtonTxt>
            </BackButton>
            <NextButton data-test-id="nextBtnTestId" onClick={this.verifyPassportTravels}>
              <NextButtonTxt>{configJSON.next}</NextButtonTxt>
            </NextButton>
          </ButtonBox>
        </Grid>
      </Grid>)
  }

  emergencyContacts = () => {
    return (
      <Grid container
      direction="row"
      justifyContent="center"
      alignContent="center"
      style={webStyle.gapTwelve}>

<Grid 
item 
xs={12}
 style={webStyle.justifyCenter}
 >
  <HeaderTypography>
    {configJSON.emergencyContactsHeader}
    </HeaderTypography></Grid>
    <PersonalInformationGrid item xs={12}>
<InputMainBox>
            <InputTxt>{configJSON.contactPerson}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
              value={this.state.emergencyAndOtherDocument.emergencyPersonName}
              placeholder={configJSON.emergencyNamePlaceHolder}
              data-test-id="emergencyPersonNameId"
            onKeyPress={this.disableNumericValue}
              onChange={(event)=>this.addEmergencyData(event,"emergencyPersonName")}
            />
            {this.state.emergencyAndOtherDocument.emergencyPersonName === "" && this.state.showError && (<RequiredTxt>
            {configJSON.emergencyPersonError}
            </RequiredTxt>)}
          </InputMainBox>
          </PersonalInformationGrid>
    <PersonalInformationGrid item xs={12}>
<InputMainBox>
              <InputTxt>
                {configJSON.mobile}<StarTxt> {configJSON.starTxt}</StarTxt>
              </InputTxt>
              <Box style={webStyle.mobileNumberWrapper}>
                  <Box>
                  <SelectInput
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  }}
                 IconComponent={KeyboardArrowDown}
              displayEmpty
              onChange={this.handleEmerCountryCodeChange}
              style={{
                color: this.state.stateValue == "none" 
                ? "#999" 
                : "#000"
              }}
              value={this.state.emercontryCodeValue}
              data-test-id="stateEmerCountryCodeTestId"
              defaultValue="default"
            >
                {this.state.countryCodes.map((item) => (
    <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>{`+${item.country_code} (${item.alpha2})`}</MenuItem>
  ))}
            </SelectInput>
                  </Box>
                  <Box style={webStyle.mobileNumberBox}>
                    <InputField
                    type="tel"
            name="number"
            data-test-id="emergencyContactId"
            value={this.state.emergencyAndOtherDocument.emergencyContact}
            onChange={this.handleEmergencyContactNumberChange}
            placeholder={configJSON.mobilePlaceholderTxt}
            />
                  </Box>
              </Box>
              {(this.state.emergencyNumberError || (this.state.emergencyAndOtherDocument.emergencyContact === "" && this.state.showError)) && (<RequiredTxt>
                {this.state.contactEmerNumberErrorTxt}
            </RequiredTxt>)}
</InputMainBox>
          </PersonalInformationGrid>
<PersonalInformationGrid item xs={12}>
<InputMainBox>
            <InputTxt>{configJSON.labelEmail}<StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
            type="email"
            data-test-id="emergencyEmailId"
            onChange={(event)=>this.addEmergencyData(event,"emergencyEmail")}
            value={this.state.emergencyAndOtherDocument.emergencyEmail}
              placeholder={configJSON.emailPlaceHolder}
            />
            {(!this.isValidEmergencyyEmail() && this.state.showError) && (<RequiredTxt>
            {configJSON.emailError}
            </RequiredTxt>)}
          </InputMainBox>
          </PersonalInformationGrid>
<Grid
 item
  xs={12}
   style={webStyle.flexAndGap}
    direction="column"
     justifyContent="center"
      alignItems="center"
      >
        <Middleheader>
    {configJSON.addDocuments}
    </Middleheader>
    <DragAndDropDescription>{configJSON.otherDocumentsAddedTxt}
  </DragAndDropDescription>
    <FileUploadRule>
<FileUploadRuleBox><strong>{configJSON.acceptedFileFormatText}</strong>{configJSON.fileType}
</FileUploadRuleBox>
<FileUploadRuleBox>
<strong>{configJSON.acceptedFileSizeText}</strong>{configJSON.aceeptFileSize}</FileUploadRuleBox>
</FileUploadRule></Grid>
<Grid item xs={12} style={webStyle.flexAndGap} direction="column" justifyContent="center" alignItems="center">

{this.state.emergencyFile.map((emerFile,index)=><UploadImageBox> 
 <>
 <Typography style={webStyle.defaultText}>{emerFile?.name}</Typography>
       <Box onClick={()=>this.removeEmergencyFile(index)} style={webStyle.DeleteIconWrapper}>
     <SvgIcon component={DeleteOutlineIcon} style={{ color: 'red' }} />
     <DeleteIcon>
       {configJSON.deleteFileUploaded}
     </DeleteIcon>
   </Box>
</>
</UploadImageBox>)
}

  <UploadImageBox > 
 <FileUploadLabel htmlFor="otherDocFile"><Box style={webStyle.uploadimageBox}>
    <img src={DragAndDrop} height={"100%"} width={"100%"}/></Box><Typography>{configJSON.dragFile}</Typography>
  <Typography>{configJSON.or}</Typography>
  <input hidden multiple type="file" id="otherDocFile" data-test-id="uploadFileId" placeholder="Choose a File To Upload"
    onChange={this.handleEmeFileChange}
  />
  <FileUploadTxtBox><Typography style={webStyle.defaultText}>{configJSON.chooseFile}</Typography></FileUploadTxtBox></FileUploadLabel>
  {this.state.isErrorEmergencyDoc && (<RequiredTxt>
            {this.state.errorEmergencyDoc}
            </RequiredTxt>)}
  </UploadImageBox> 
 </Grid>
  <PersonalInformationGrid item xs={12}>
  <InputMainBox>
  <InputTxt>{configJSON.remarks}
            </InputTxt>
          <InputFieldMultiline
            multiline
            value={this.state.emergencyAndOtherDocument.remarks}
            onChange={(event)=>this.addEmergencyData(event,"remarks")}
            placeholder={configJSON.remarksPlaceHolder}
            data-test-id="messageTestId"
            inputProps={{ maxLength: 1000 }}
          />
          <Typography variant="caption" color="textSecondary">
          Only 1000 characters allowed
        </Typography>
        </InputMainBox>
          </PersonalInformationGrid>
<Grid item xs={12} style={webStyle.paddingXTwenty}>
      <ButtonBox>
        <BackButton data-test-id="backBtnTestId" onClick={this.backButtonClick}>
          <BackButtonTxt>{configJSON.back}</BackButtonTxt>
        </BackButton>
        <NextButton data-test-id="nextBtnTestId" onClick={this.postStudentFormApi}>
          <NextButtonTxt>{configJSON.next}</NextButtonTxt>
        </NextButton>
      </ButtonBox>
</Grid>
  </Grid>)}

   renderSuccessPopUp = () => {
    return(
<Modal
  open={this.state.successPopUp}
  onClose={this.handleClose}
  style={webStyle.modal}
>
  <ModalBox>
  <Box style={{...webStyle.flexEnd}}>
    <Button data-test-id="handleCloseId"  onClick={this.handleClose}>
  <CloseIcon />
  </Button>
  </Box>
  <Box style={{...webStyle.gapTwelve,...webStyle.justifyCenter,flexDirection:"column",alignItems:"center"}}>
    <img src={successTick} width={"66px"} height={"61px"}/>
    <Typography variant="h6" component="h2" style={{fontSize:"24px",fontWeight:700}}>
      {configJSON.success}
    </Typography>
    <Typography>
      {configJSON.successMessage}
    </Typography>
  </Box>
  <Box style={{...webStyle.justifyCenter,alignItems:"center"}}>
            <NextButton
            data-test-id="goToAllCourse"
              onClick={this.navigateToAllCourses}
            >
              <NextButtonTxt>
                {configJSON.close}
              </NextButtonTxt>
  </NextButton>
 
  </Box>
  <Box style={{...webStyle.justifyCenter,alignItems:"center"}}>
  <BackButton data-test-id="goOtherCourse" onClick={this.navigateToOtherService}>
          <BackButtonTxt>{configJSON.goOtherCourse}</BackButtonTxt>
        </BackButton>
  </Box>
  </ModalBox>
</Modal>
    )
  }

    showErrorToast = () => {

      return (
        <ToastContainer
      position= "top-right"
      autoClose= {3000}
      hideProgressBar= {false}
      closeOnClick= {true}
      pauseOnHover= {true}
      draggable= {true}
      theme= "colored"
      transition= {Bounce}
    />
      )
    }

  handleRender = () => {
    switch (this.state.activeStep){
      case 1:
        return this.personalInformationForm();
      case 2:
        return this.educationDetails();
      case 3:
        return this.englishTestScore();
      case 4:
        return this.workExperience();
      case 5:
        return this.passportAndTravels();
      case 6:
        return this.emergencyContacts();
      default:
        return this.personalInformationForm()
    }
  }

      CustomInput = ({
        value,
        onClick,
      }: {
        value?: string;
        onClick?: () => void;
      }) => (
        <>
        <DateInputBox className="selectInput autocompleteField" onClick={onClick} data-test-id="dateInputId">
          <input type="text" style={{border:"none"}} value={value} readOnly placeholder="Choose Period" />
          <CalendarWrapper>
            <CalendarTodayIcon />
          </CalendarWrapper>
        </DateInputBox>
        </>
      );

  render() {
    const steps = ["Personal Information", "Education", "English Test Score", "Work Experience", "Passport & Travel History", "Emergency Contact & Other Documents"]
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Prompt
            when={this.state.shouldBlockNavigation && !this.state.successPopUp}
            message={this.state.BackButtonText}
          />
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <ContentBox>
            <SideMenuBar data-test-id="sidebarNavigationBtn"
              navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />
            <GridBox >
              {this.showErrorToast()}
              <Grid container style={webStyle.containerStyle}>
                <Grid xs={12} item>
                  <FlexEndTypography data-test-id="modalText">
                    {configJSON.addStudent}
                  </FlexEndTypography>
                </Grid>
                <Grid xs={12} item>
                  <StepperResponsive style={webStyle.backgroundColor} activeStep={this.state.activeStep - 1} alternativeLabel connector={<QontoConnector />}>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={this.QontoStepIcon}>
                          <StepperTypography style={{
                            color: this.state.activeStep - 1 >= index ? "black" : "rgba(0, 0, 0, 0.54)"
                          }}>{label}</StepperTypography></StepLabel>
                      </Step>
                    ))}
                  </StepperResponsive>
                </Grid>
                <Grid xs={12} item>
                  <Container maxWidth="sm">
                    {this.handleRender()}
                  </Container>
                </Grid>
              </Grid>
              {this.renderSuccessPopUp()}
            </GridBox>
          </ContentBox>

        </Box>
      </ThemeProvider>
    );
  }
}

function RadioButton(props: RadioProps) {
  return (
    <CustomizeRadio
      disableRipple
      color="default"
      checkedIcon={<CheckCircle />}
      {...props}
    />
  );
}


const CalendarWrapper = styled(Box)({
  position: "absolute",
  right: "10px", 
  color: "#757575", 
});

const DateInputBox = styled(Box)({
  position: "relative",
  alignItems: "center",
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  background:"white",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: '#64748B',
    fontWeight: 700,
      opacity: 10,
    },
  },
});

const ContentBox = styled(Grid)({
  display:"flex",
  "& .countrySelect": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    background:"white",
    display: "flex",
    justifyContent: "center",
    padding: "0px 0px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: '#64748B',
      fontWeight: 700,
        opacity: 10,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiAutocomplete-input": {
      padding: "4.5px 4px 7.5px 5px !important"
    }
  }
})
const GridBox = styled(Grid)({
});

const UploadImageBox = styled(Box)({
  display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",
  cursor:"pointer",
  border:"1px dashed #334155",height:"210px",borderRadius:"10px",margin:"75px 0",
  "@media (max-width:960px)": {
    width:"319px",
      },
      "@media (min-width:720px)": {
        width:"519px",
      },
})

const DragAndDropDescription = styled(Typography)(
  {textAlign:"center",
  color:"#334155",
  fontSize:"16px",
  fontWeight:400,
  lineHeight:"24px",
  "@media (max-width:960px)": {
    width:"350px",
      },
      "@media (min-width:720px)": {
        width:"1013px",
      },
    }
)

const QontoConnector = withStyles(({breakpoints})=>({
  alternativeLabel: {
    top: 13,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  completed: {
    '& $line': {
      borderColor: '#34D399',
    },
  },
  line: {
    borderColor: '#D9D9D9',
    borderTopWidth: "6px",
    borderRadius: 1,
  },
  [breakpoints.down("md")]:{
    alternativeLabel: {
    top: 15,
    left: 'calc(-40% + 0.2px)',
    right: 'calc(60% + 0.1px)',
    },
    line: {
      borderTopWidth: "2px",
    }
  },
  [breakpoints.down("sm")]:{
    alternativeLabel: {
    top: 8,
    left: 'calc(-25% + 0.6px)',
    right: 'calc(70% + 0.6px)',
    },
    line: {
      borderTopWidth: "2px",
    }
  },
}))(StepConnector);

const ActiveConnecter = styled(Check)({
  "@media (max-width:960px)": {
    backgroundColor:"#34D399",color:"white",
    width:"18px",height:"18px",borderRadius:"50%"
      },
      "@media (min-width:720px)": {
        backgroundColor:"#34D399",color:"white",
        width:"24px",height:"24px",borderRadius:"50%",padding:3
      },
      },
)

const DeactiveConnecter = styled("div")({
  "@media (max-width:960px)": {
    backgroundColor:"white",color:"white",
    width:"18px",height:"18px",borderRadius:"50%",border:"1px solid #34D399"
      },
      "@media (min-width:720px)": {
        backgroundColor:"white",color:"white",
        width:"24px",height:"24px",borderRadius:"50%",padding:3,border:"1px solid #34D399"
      },
      },
)

const ModalBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  backgroundColor: "#FFFFFF",
  border: '2px solid #FFFFFF',
  borderRadius:"8px",
  gap:"24px",
  padding:"30px 20px",
  "@media (max-width:960px)": {
    maxWidth:"448px",
      },
      "@media (min-width:720px)": {
        width:"448px",
      },
      })

const InputFieldMultiline = styled(TextField)({
  width: "100%", border: "1px solid #CBD5E1",
  borderRadius: "8px", minHeight: "145px", margin: "5px 0px",
  padding: "0px 10px", boxSizing: "border-box",
  backgroundColor:"white",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  }
});

const CusomiseRadioGroup = styled(RadioGroup)({
  gap:"10%"
})

const CustomizeRadio = styled(Radio)({
  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
            {
                color: '#34D399',
                width:24,
                height:24
            },
        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
            color: '#34D399',
            width:24,
            height:24
        },
})

const FileUploadTxtBox = styled(Box)({
  border:"1px solid #34D399",
  width:"216px",
  height:"30px",
  borderRadius:"5px",
  display:"flex",
  justifyContent:"center",
alignItems:"center"});

const StepperResponsive = styled(Stepper)({
  "&.MuiStepper-root": {
    display: "flex",
    padding: "24px",
    justifyContent: "center",
  },
})
const StepperTypography = styled(Typography)({
  "@media (max-width:960px)": {
    fontWeight:700,fontSize:"8px",
  },
  "@media (min-width:720px)": {
    fontWeight:700,fontSize:"16px",
  }  
})

const NextButtonTxt = styled(Typography)({textTransform:"none",color:"#ffff",fontWeight:700,fontSize:"16px"});

const BackButtonTxt = styled(Typography)({textTransform:"none",color:"#059669",fontWeight:700,fontSize:"16px"});

const BackButton = styled(Button)({
  width:"224px",
  height:"44px",
  borderRadius:"8px",
  border:"1px solid #34D399",
  padding:"10px 16px",
  gap:"8px",
  background:"#D6F6EB",
  textAlign:"center"
});

const ButtonBox = styled(Box)({display:"flex",justifyContent:"center",gap:"40px",marginTop:"2rem",marginBottom:"3rem"});

const NextButton = styled(Button)({
  width:"224px",
  height:"44px",
  borderRadius:"8px",
  border:"1px solid #34D399",
  padding:"10px 16px",
  gap:"8px",
  background:"#34D399",
  textAlign:"center",
  "&.MuiButton-root:hover": {
    backgroundColor: "#34D399"
  }
});

const PersonalInformationGrid = styled(Grid)({
  padding:"0 20px",
  '& .PrivateNotchedOutline-root-65':{
    border:'none'
  }
})
const HeaderTypography = styled(Typography)({
fontWeight:700,
fontSize:"24px",
lineHeight:"30.24px",
textAlign:"center",margin:"40px 0"
})

const Middleheader = styled(Typography)({
  fontWeight:700,
  fontSize:"24px",
  lineHeight:"30.24px",
  textAlign:"center",margin:"10px 0"
  })

const FlexEndTypography = styled(Typography)({
  fontWeight:700,
  lineHeight:"27.72px",
  textAlign:"start",
  "@media (max-width:960px)": {
    fontSize:"18px",margin:"20px 40px",
  },
  "@media (min-width:720px)": {
    fontSize:"22px",margin:"40px 0",
  } 
  })

const InputMainBox = styled(Box)({
  margin: "20px 0px"
});

const InputTxt = styled(Typography)({
  color: "#000",
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "Plus Jakarta Sans"
});

const InputTxtHeader = styled(Typography)({
  color: "#000",
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "Plus Jakarta Sans",
  textTransform: "capitalize",
});

const StarTxt = styled("span")({
  color: "black",
  fontFamily: "Plus Jakarta Sans"
});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
})

const InputField = styled(TextField)({
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  background:"white",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: '#64748B',
    fontWeight: 700,
      opacity: 10,
    },
  },
});

const SelectInput = styled(Select)({
  width: "100%",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  background:"white",
  height: "50px",
  display: "flex",
  margin: "5px 0px",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "0px 10px",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&::before": {
      content: "none"
    },
    "&:focus": {
      outline: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  "& .MuiInputBase-input":{
    color: '#64748B',
    fontWeight:700,
  }
});

const DeleteIcon = styled(Typography)({
  color: "var(--Status-Red-600, #DC2626)",
  textAlign: "center",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px"
})

const webStyle = {
  justifyCenter:{
    display:"flex",
    justifyContent:"center"
  },
  flexCenter:{
     display:"flex",
     alignItems:"center"
  },
  uploadimageBox:{
    width:"80px", height:"80px"
  },
  flexAndGap:{gap:"10px",
  display:"flex"},
  mainWrapper: {
    display: "flex",
    paddingBottom: "30px",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
  },
  containerStyle: {
     padding: "20px 100px",
     background:"#F7F7F7" 
    },
    backgroundColor: {
      background:"#F7F7F7" 
     },
    paddingXTwenty:{
      padding:"0 20px"
    },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    display: "flex",
    height: "100px",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  circle: {
    backgroundColor:"white",color:"white",
      width:"24px",height:"24px",borderRadius:"50%",padding:3,border:"1px solid #34D399"
  },
  completed: {
    backgroundColor:"#34D399",color:"white",
      width:"24px",height:"24px",borderRadius:"50%",padding:3
  },
  buttonStyle: {
    width: "100%",
    marginTop: "40px",
    height: "45px",
    backgroundColor: "rgb(98, 0, 238)",
    border: "none"
  },
  gapTwelve:{gap:"12px"},
  defaultText:{
    color:"#34D399"
  ,fontWeight:600,
  fontSize:"16px"
},
displayNone:{
  display:"none"
},
themeColorAndCursor:{
  cursor: "pointer",
  color: "#34D399"
},
cursorPointer:{
  cursor:"pointer"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
modal: {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
},
  paper: {
    border: '2px solid #FFFFFF',
    display: "flex",
    maxWidth: "448px",
    backgroundColor: "#FFFFFF",
    gap: "24px",
    height: "265px",
    borderRadius: "8px",
    padding: "30px 20px"
  },
  DeleteIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "191px",
    height: "30px",
    flexShrink: 0,
    borderRadius: "5px",
    border: "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    background: "var(--Basic-White, #FFF)",
    marginTop: "16px",
    padding: "12px",
    gap: "12px",
  },
  mobileNumberWrapper: {
    display:"flex",
    gap:"24px"
  },
  mobileNumberBox: {
    width:"100%"
  },
};

const FileUploadRuleBox = styled(Typography)(
  {textAlign:"center",
  color:"#334155",
  fontSize:"16px",
  fontWeight:400,
  lineHeight:"24px",
  "@media (max-width:960px)": {
    width:"350px",
      },
      "@media (max-width:959px)": {
        width:"100% !important",
        display:'flex',
        justifyContent:'center',
        flexWrap: 'wrap',
          },
    }
)
const FileUploadRule = styled(Box)({
  width: "443px",
  height: "56px",
  gap: "0px",
  borderRadius: "20px",
  border: "1px solid #E2E8F0",
  backgroundColor: "#F8FAFC",
  padding:"10px"
  
})

const FileUploadLabel = styled("label")({
display:"flex",
justifyContent:"center",
alignItems:"center",
flexDirection:"column"
});
// Customizable Area End
